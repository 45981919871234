import { createRouter, createWebHistory } from 'vue-router'

const routerHistory = createWebHistory()

const router = createRouter({
  history: routerHistory,
  routes: [
    {
      path: '/promotion/:bookId/:chapterId',
      name: '推广链接',
      component: () => import('../views/promotion')
    },
    {
      path: '/active/:activeId',
      name: '活动链接',
      component: () => import('../views/active')
    }
  ]
})

export default router
